import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { register } from 'swiper/element/bundle';
register();

function SectionSlider() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const isStandardUser = isAuthenticated && userData.super_role_id === 0;

    const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    // Initialize Swiper
    const swiperEl = document.querySelector('swiper-container');
    
    // Add slideChange event listener
    swiperEl?.addEventListener('slidechange', (e) => {
      setActiveSlide(e.detail[0].realIndex);
    });

    return () => {
      swiperEl?.removeEventListener('slidechange', () => {});
    };
  }, []);

  const slides = [
    {
      smallTitle: "Welcome to BuyByeQ Platform",
      largeTitle: "One Platform. Endless Possibilities in Hospitality!",
      info: "BuyByeQ is the tech backbone of modern hospitality - all in one place. Because running a hospitality business should be about great experiences, not great headaches!"
    },
    {
      smallTitle: "Welcome to BuyByeQ Platform",
      largeTitle: "Here, Hospitality Meets Efficiency!",
      info: "Great service starts with great technology. BuyByeQ ensures happy customers, and stress-free operations...."
    }
  ];

    return (
        <>
        <div  style={{minHeight:"600px",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"50px"}}>
        <swiper-container
      loop="true"
      space-between="30"
      effect="fade"
      navigation-next-el=".swiper-button-next"
      navigation-prev-el=".swiper-button-prev"
      pagination-el=".swiper-pagination"
      pagination-clickable="true"
      parallax="true"
      autoplay-delay="7000"
      autoplay-disable-on-interaction="true"
      class="swiper-wrapper"
    >
      {slides.map((slide, index) => (
        <swiper-slide key={index} class="sx-bnr-2-wrap swiper-slide ">
          <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
          <div className="container px-4 px-md-5">
            <div className="row d-flex align-items-center justify-content-between"
        style={{ minHeight: '60vh' }}>
              <div className="col-12 col-md-8 col-lg-7 pe-md-4 d-flex flex-column align-items-center align-items-md-start text-center text-md-start">
              <div 
            className={`transition-opacity duration-500 ${activeSlide === index ? 'opacity-100' : 'opacity-0'}`}
          >
                   <span 
              className="d-block mb-2 sx-bnr-2-small-title" 
              style={{ color: '#5B1873', fontWeight: "500", fontSize: '1rem' ,width:"80%",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}
            >
              {slide.smallTitle}
            </span>
            <h2
              className="mb-3 sx-bnr-2-large-title" 
              style={{ color: '#5B1873', fontWeight: "500", fontSize: 'clamp(1.5rem, 4vw, 4rem)' }}
            >
              {slide.largeTitle}
            </h2>
            <p 
              className="mb-4 sx-bnr-2-info" 
              style={{ color: '#5B1873', fontWeight: "500", fontSize: 'clamp(0.875rem, 3vw, 1rem)' ,width:"80%",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}
            >
              {slide.info}
            </p>
                <div className="sx-bnr-readmore" style={{width:"80%",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}>
                  {!isAuthenticated ? (
                    <NavLink to="/registration" className="site-button sx-btn-primary icon">
                      <i className="fa fa-long-arrow-right" />
                      Register Your Business
                    </NavLink>
                  ) : (
                    <NavLink to={isStandardUser ? "/config" : "/agents/dashboard"} className="site-button sx-btn-primary icon">
                      <i className="fa fa-long-arrow-right" />
                      View My Dashboard
                    </NavLink>
                  )}
                </div>
                </div>
              </div>

              <div className="col-12 col-md-4 col-lg-5 text-center">
                <img src="/buybyeqsliderimg.png" alt="Hospitality Image" className="img-fluid mx-auto d-block" 
            style={{ maxHeight: '50vh', objectFit: 'contain', width: '100%' }} />
              </div>
            </div>
          </div>
        </swiper-slide>
      ))}
    </swiper-container>
            {/* <div className="swiper-button-next" /> */}
            {/* <div className="swiper-button-prev" /> */}
        </div>
    </>
    )
}

export default SectionSlider;