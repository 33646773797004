import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import SectionBlogSingle from "./components/detail/section-blog-single";
import SectionLatestBlogs from "./components/detail/section-latest-blogs";
import Banner from "../../../sections/common/banner";
import { bannerData } from "../../../../globals/banner";

function BlogDetailPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentBlog = location.state?.blog; // Current blog passed from BlogGridPage
    console.log(currentBlog);
    

    // Fetch all blogs to determine next and previous posts
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`content/all`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: false,
                });

                const parsedBlogs = response.data.blogs?.map((blog) => ({
                    ...blog,
                    content: blog.content ? JSON.parse(blog.content).blocks[0].text : "",
                })) || [];

                setBlogs(parsedBlogs);
            } catch (error) {
                console.log("Error fetching blogs: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return   <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh", 
            padding: "40px 0", 
            backgroundColor: "#f9f9f9", 
        }}
    >
        {/* Skeleton Loading Animation */}
        <div
            style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                backgroundColor: "#e0e0e0",
                animation: "pulse 1.5s infinite ease-in-out",
                marginBottom: "20px",
            }}
        ></div>

        {/* Loading Text */}
        <p
            style={{
                fontSize: "1.2rem",
                color: "#333",
                fontWeight: "500",
                marginTop: "20px",
            }}
        >
            {currentBlog.title}...
        </p>

        {/* CSS Animation Keyframes */}
        <style>
            {`
                @keyframes pulse {
                    0% {
                        transform: scale(0.9);
                        opacity: 0.7;
                    }
                    50% {
                        transform: scale(1);
                        opacity: 1;
                    }
                    100% {
                        transform: scale(0.9);
                        opacity: 0.7;
                    }
                }
            `}
        </style>
    </div>;
    }

    if (!currentBlog) {
        return <div>No blog data found.</div>;
    }

    // Find the index of the current blog
    const currentIndex = blogs.findIndex((blog) => blog.id === currentBlog.id);

    // Determine next and previous blogs
    const previousBlog = currentIndex > 0 ? blogs[currentIndex - 1] : null;
    const nextBlog = currentIndex < blogs.length - 1 ? blogs[currentIndex + 1] : null;

    // Only show next/previous buttons and related blogs if there are multiple blogs
    const showNavigationButtons = blogs.length > 1;
    const showRelatedBlogs = blogs.length > 1;

    return (
        <>
         <Banner _data={bannerData.blogDetails} />
            {/* SECTION CONTENT START */}
            <div className="section-full p-t100 p-b70 mobile-page-padding">
                <div className="container">
                    <div className="blog-single-space-wrap max-w800 m-lr-auto">
                        <>
                            <SectionBlogSingle
                                blog={currentBlog}
                                previousBlog={showNavigationButtons ? previousBlog : null}
                                nextBlog={showNavigationButtons ? nextBlog : null}
                                navigate={navigate}
                            />
                            {showRelatedBlogs && <SectionLatestBlogs blogs={blogs} />}
                        </>
                    </div>
                </div>
            </div>
            {/* SECTION CONTENT END */}
        </>
    );
}

export default BlogDetailPage;