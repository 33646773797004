import React, { useState, useEffect } from 'react';
import {
    Drawer, List, ListItem, ListItemIcon, ListItemText, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Modal, Box, TextField, Rating, Typography, Avatar, Breadcrumbs, Link, Divider, Grid, Stack, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, CircularProgress,
    Card
} from "@mui/material";
import { Add, BookmarkAddedOutlined, TakeoutDiningTwoTone } from "@mui/icons-material";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { orange } from "@mui/material/colors";
import axios from "../../../src/api/axios";

const TABS = {
    Blogs: "Blogs",
    Testimonials: "Testimonials",
};

export default function WebAdminDashboard() {
    const [selectedTab, setSelectedTab] = useState(TABS.Blogs);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [modalData, setModalData] = useState({ id: null, image: "", name: "", rating: 0, date: "", title: "", customer_name: "", restaurant_name: "", product_name: "" });
    const [localData, setLocalData] = useState({ Blogs: [], Testimonials: [] });
    const [itemToDelete, setItemToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`content/all`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
            });

            // Function to validate RawDraftContentState
            const isValidRawDraftContentState = (content) => {
                return (
                    content &&
                    typeof content === 'object' &&
                    Array.isArray(content.blocks) &&
                    typeof content.entityMap === 'object'
                );
            };


            const blogs = response.data.blogs.map(blog => {
                const parsedContent = blog.content ? JSON.parse(blog.content) : null;
                return {
                    ...blog,
                    content: parsedContent && isValidRawDraftContentState(parsedContent)
                        ? EditorState.createWithContent(convertFromRaw(parsedContent))
                        : EditorState.createEmpty(),
                };
            });

            const testimonials = response.data.testimonials.map(testimonial => {
                const parsedContent = testimonial.description ? JSON.parse(testimonial.description) : null;
                return {
                    ...testimonial,
                    description: parsedContent && isValidRawDraftContentState(parsedContent)
                        ? EditorState.createWithContent(convertFromRaw(parsedContent))
                        : EditorState.createEmpty(),
                };
            });



            setLocalData({ Blogs: blogs, Testimonials: testimonials });
        } catch (error) {
            console.log("Error fetching content. " + error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (data = {
        id: null,
        image: "",
        name: "",
        rating: 0,
        date: "",
        title: "",
        customer_name: "",
        restaurant_name: "",
        product_name: "",
        content: EditorState.createEmpty(), 
        description: EditorState.createEmpty(),
    }) => {
        console.log("📥 Data received:", data);
    
        let parsedContent = null;
    
        try {
            if (selectedTab === TABS.Blogs && data.content) {
                console.log("🔹 Parsing blog content...");
                parsedContent = (typeof data.content === "string")
                    ? JSON.parse(data.content)
                    : data.content;
            } else if (selectedTab === TABS.Testimonials && data.description) {
                console.log("🔹 Parsing testimonials content...");
                parsedContent = (typeof data.description === "string")
                    ? JSON.parse(data.description)
                    : data.description;
            }
        } catch (error) {
            console.error("⚠️ JSON Parse Error:", error, "for data:", data);
            parsedContent = null;
        }
    
        console.log("📝 Parsed Content Before Validation:", parsedContent);
    
        // If parsedContent is already an EditorState, use it directly
        let description;
        if (parsedContent instanceof EditorState) {
            console.log("✅ Using existing EditorState.");
            description = parsedContent;
        } else if (parsedContent && parsedContent.blocks) {
            try {
                description = EditorState.createWithContent(convertFromRaw(parsedContent));
                console.log("✅ Successfully converted content.");
            } catch (error) {
                console.error("🚨 convertFromRaw() Failed:", error);
                description = EditorState.createEmpty();
            }
        } else {
            console.warn("⚠️ Using empty EditorState due to invalid content.");
            description = EditorState.createEmpty();
        }
    
        const modalData = { ...data, description };
        setModalData(modalData);
        console.log("📦 Final Modal Data:", modalData);
    
        setOpenModal(true);
    };
    



    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSave = async () => {
        setSaving(true);
        const formData = new FormData();
        const isBlog = selectedTab === TABS.Blogs;
        const isTestimonial = selectedTab === TABS.Testimonials;

        // Validate EditorState
        if (isBlog && !modalData.content) {
            alert("Content is required for Blogs!");
            setSaving(false);
            return;
        }
        if (isTestimonial && !modalData.description) {
            alert("Description is required for Testimonials!");
            setSaving(false);
            return;
        }

        // Convert EditorState to raw content
        const descriptionContent = modalData.description
            ? convertToRaw(modalData.description.getCurrentContent())
            : null;
        const blogContent = modalData.content
            ? convertToRaw(modalData.content.getCurrentContent())
            : null;

        if (isBlog) {
            if (!modalData.title || !blogContent || !modalData.image) {
                alert("Title, Image, and Content are required for Blogs!");
                setSaving(false);
                return;
            }
        } else if (isTestimonial) {
            if (!modalData.customer_name || !descriptionContent) {
                alert("Customer Name and Description are required for Testimonials!");
                setSaving(false);
                return;
            }
        }

        // Append data to formData
        if (isBlog) {
            formData.append("title", modalData.title);
            formData.append("content", JSON.stringify(blogContent));
            if (modalData.image instanceof File) {
                formData.append("image", modalData.image);
            }
        } else if (isTestimonial) {
            formData.append("customer_name", modalData.customer_name);
            formData.append("restaurant_name", modalData.restaurant_name);
            formData.append("product_name", modalData.product_name);
            formData.append("description", JSON.stringify(descriptionContent));
            formData.append("rating", modalData.rating || 5);
            if (modalData.image instanceof File) {
                formData.append("image", modalData.image);
            }
        }

        try {
            const response = modalData.id == null
                ? await axios.post(`/content/${selectedTab.toLowerCase()}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: false,
                })
                : await axios.put(`/content/update/${selectedTab.toLowerCase()}/${modalData.id}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: false,
                });

            console.log("Saved successfully:", response.data);
            fetchContent();
            handleCloseModal();
        } catch (error) {
            console.error("Error saving content:", error);
            alert("Failed to save the record. Please try again.");
        } finally {
            setSaving(false);
        }
    };

    const handleDelete = async (id) => {
        setDeleting(true);
        try {
            await axios.delete(`/content/delete/${selectedTab === TABS.Blogs ? "blogs" : "testimonials"}/${id}`, {
                withCredentials: false,
            });

            const filteredData = localData[selectedTab].filter(item => item.id !== id);
            setLocalData({ ...localData, [selectedTab]: filteredData });
        } catch (error) {
            console.error("Error deleting content:", error);
            alert("Failed to delete the record. Please try again.");
        } finally {
            setOpenDeleteDialog(false);
            setDeleting(false);
        }
    };


    const handleOpenDeleteDialog = (id) => {
        setItemToDelete(id);
        setOpenDeleteDialog(true);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Drawer variant="permanent" sx={{ width: 240, flexShrink: 0 }}>
                <div className="top flex items-center gap-4">
                    <Typography variant="h6" sx={{ p: 2, bgcolor: orange[500], color: "white" }}>
                        BuyByeQ Web Admin
                    </Typography>
                </div>
                <Divider />
                <List>
                    <ListItem button selected={selectedTab === TABS.Blogs} onClick={() => setSelectedTab(TABS.Blogs)}>
                        <ListItemIcon><BookmarkAddedOutlined /></ListItemIcon>
                        <ListItemText primary="Blogs" />
                    </ListItem>
                    <ListItem button selected={selectedTab === TABS.Testimonials} onClick={() => setSelectedTab(TABS.Testimonials)}>
                        <ListItemIcon><TakeoutDiningTwoTone /></ListItemIcon>
                        <ListItemText primary="Testimonials" />
                    </ListItem>
                </List>
            </Drawer>
            <Container sx={{ flexGrow: 1, p: 3 }}>
                <Breadcrumbs sx={{ mb: 2 }}>
                    <Link underline="hover" color="inherit" href="#">
                        Content Management
                    </Link>
                    <Typography color="text.primary">{selectedTab}</Typography>
                </Breadcrumbs>
                <Button variant="contained" startIcon={<Add />} sx={{ bgcolor: orange[500], mt: 2 }} onClick={() => handleOpenModal()}>Add New</Button>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {selectedTab === 'Blogs' ? 'Blog Image' : 'Customer Image'}
                                    </TableCell>
                                    <TableCell>
                                        {selectedTab === 'Blogs' ? 'Blog Title' : 'Customer'}
                                    </TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {localData[selectedTab].map((row) => {
                                    const imageUrl = `${axios.defaults.baseURL}${row.image}`.replace(/([^:]\/)\/+/g, "$1");
                                    // console.log("Image URL:", imageUrl);
                                    return (
                                        <TableRow key={row.id} hover>
                                            <TableCell>
                                                <Avatar
                                                    src={imageUrl}
                                                    alt={row.name}
                                                    sx={
                                                        selectedTab === 'Blogs'
                                                            ? { width: 100, height: 100, borderRadius: 0 }
                                                            : { width: 50, height: 50, borderRadius: '50%' }
                                                    }
                                                />
                                            </TableCell>

                                            <TableCell>
                                                {selectedTab === 'Blogs'
                                                    ? row.title
                                                    : `${row.customer_name || ''} | ${row.product_name || ''}`
                                                        .trim()
                                                        .replace(/^ \| | \| $/g, '')}
                                            </TableCell>

                                            <TableCell>
                                                <IconButton color="success" onClick={() => handleOpenModal(row)}>
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </IconButton>
                                                <IconButton color="error" onClick={() => handleOpenDeleteDialog(row.id)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Modal open={openModal} onClose={handleCloseModal}>
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "50%",
                        height: "80%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        overflowY: "auto"
                    }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>{modalData.id ? "Edit" : "Add"} {selectedTab}</Typography>
                        <Grid container spacing={2}>
                            {selectedTab === TABS.Blogs ? (
                                <Grid container spacing={3} sx={{ p: 3 }}>
                                    {/* Title */}
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Title:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            fullWidth
                                            value={modalData.title}
                                            onChange={(e) => setModalData({ ...modalData, title: e.target.value })}
                                            required
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>

                                    {/* Featured Image Section */}
                                    <Grid container spacing={2} alignItems="center">
                                        {/* Featured Image Display */}
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                                Featured Image:
                                            </Typography>
                                            {modalData.image && !modalData.preview && (
                                                <Card
                                                    sx={{
                                                        maxWidth: 150,
                                                        maxHeight: 150,
                                                        borderRadius: 2,
                                                        overflow: "hidden",
                                                        boxShadow: 2,
                                                        mt: 1,
                                                    }}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={`${axios.defaults.baseURL}${modalData.image}`.replace(/([^:]\/)\/+/g, "$1")}
                                                        alt="Featured Image"
                                                        sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                    />
                                                </Card>
                                            )}
                                        </Grid>

                                        {/* File Upload Input */}
                                        <Grid item xs={12} sm={8}>
                                            <Box sx={{ pt: 2, pl: 2 }}>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file) {
                                                            const imageUrl = URL.createObjectURL(file);
                                                            setModalData({ ...modalData, image: file, preview: imageUrl });
                                                        }
                                                    }}
                                                    style={{ marginBottom: 16 }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    {/* Image Preview */}
                                    {modalData.preview && (
                                        <Grid item xs={12}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
                                                <Avatar
                                                    src={modalData.preview}
                                                    alt="Preview"
                                                    sx={{ width: 100, height: 100, borderRadius: 2, boxShadow: 2 }}
                                                />
                                            </Box>
                                        </Grid>
                                    )}

                                    {/* Content Editor */}
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Content:
                                        </Typography>
                                        <Box sx={{ border: "1px solid #ccc", borderRadius: 1, p: 1 }}>
                                            <Editor
                                                editorState={modalData.content}
                                                onEditorStateChange={(content) => setModalData({ ...modalData, content })}
                                                toolbarStyle={{ border: "none" }}
                                                wrapperStyle={{ border: "none" }}
                                                editorStyle={{ minHeight: 200, padding: "0 10px" }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={3} sx={{ p: 3 }}>

                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Customer Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            fullWidth
                                            value={modalData.customer_name}
                                            onChange={(e) => setModalData({ ...modalData, customer_name: e.target.value })}
                                            required
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Restaurant Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            fullWidth
                                            value={modalData.restaurant_name}
                                            onChange={(e) => setModalData({ ...modalData, restaurant_name: e.target.value })}
                                            required
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Product Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            fullWidth
                                            value={modalData.product_name}
                                            onChange={(e) => setModalData({ ...modalData, product_name: e.target.value })}
                                            required
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Customer Image:
                                        </Typography>
                                        {modalData.image && !modalData.preview && (
                                            <Card
                                                sx={{
                                                    maxWidth: 150,
                                                    maxHeight: 150,
                                                    borderRadius: 2,
                                                    overflow: "hidden",
                                                    boxShadow: 2,
                                                    mt: 1,
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    src={`${axios.defaults.baseURL}${modalData.image}`.replace(/([^:]\/)\/+/g, "$1")}
                                                    alt="Customer Image"
                                                    sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                />
                                            </Card>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const imageUrl = URL.createObjectURL(file);
                                                    setModalData({ ...modalData, image: file, preview: imageUrl });
                                                }
                                            }}
                                            style={{ marginBottom: 16 }}
                                        />
                                        {modalData.preview && (
                                            <Avatar
                                                src={modalData.preview}
                                                alt="Preview"
                                                sx={{ width: 100, height: 100, borderRadius: 1, mb: 2 }}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Rating:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Rating
                                            value={modalData.rating}
                                            onChange={(event, newValue) => setModalData({ ...modalData, rating: newValue })}
                                            size="large"
                                            sx={{ mt: 1 }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Description:
                                        </Typography>
                                        <Box sx={{ border: "1px solid #ccc", borderRadius: 1, p: 1 }}>
                                            <Editor
                                                editorState={modalData.description}
                                                onEditorStateChange={(newState) => {
                                                    // console.log("New Editor State:", newState); 
                                                    setModalData({ ...modalData, description: newState });
                                                }}
                                                toolbarStyle={{ border: "none" }}
                                                wrapperStyle={{ border: "none" }}
                                                editorStyle={{ minHeight: 150, padding: "0 10px" }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button variant="contained" fullWidth sx={{ bgcolor: orange[500], mt: 2 }} onClick={handleSave} disabled={saving}>
                                    {saving ? <CircularProgress size={24} /> : modalData.id ? "Update" : "Add"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this item? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                        <Button onClick={() => handleDelete(itemToDelete)} color="error" disabled={deleting}>
                            {deleting ? <CircularProgress size={24} /> : "Delete"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    );
}