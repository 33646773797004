import axios from 'axios';

// export default axios.create({
//     baseURL: 'http://195.133.93.175:5001/'
// });

export default axios.create({
    baseURL: 'https://apis.buybyeq.com/'
});

// export default axios.create({
//     baseURL: 'http://localhost:3000/'
// });