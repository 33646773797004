import { NavLink } from "react-router-dom";
import axios from "../../../../../../api/axios";

function SectionLatestBlogs({ blogs }) {
    // Display the latest 2 blogs (excluding the current blog)
    const latestBlogs = blogs.slice(0, 2);

    return (
        <>
            <div className="sx-latest-blog-outer p-b70">
                {/* TITLE START */}
                <div className="section-head center">
                    <div className="sx-head-s-title">Our Blogs</div>
                    <div className="sx-head-l-title">
                        <h2 className="sx-title">More Posts On BuyByeQ</h2>
                    </div>
                </div>
                {/* TITLE END */}
                <div className="section-content">
                    <div className="row">
                        {latestBlogs.map((blog) => {
                            const imageUrl = `${axios.defaults.baseURL}${blog.image}`.replace(/([^:]\/)\/+/g, "$1");
                            return (
                                <div className="col-lg-6 col-md-6" key={blog.id}>
                                    <div className="sx-latest-post-st-1">
                                        <div className="sx-post-media sx-img-effect img-reflection">
                                            <NavLink to="/blogs/detail" state={{ blog }}>
                                                <img src={imageUrl} alt="Blog Image" />
                                            </NavLink>
                                        </div>
                                        <div className="sx-post-info p-t30">
                                            <div className="sx-post-meta ">
                                                <ul>
                                                    <li className="post-date">
                                                        <NavLink to="/blogs/detail" state={{ blog }}>
                                                            <i className="fa fa-calendar" />
                                                            {new Date(blog.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                                        </NavLink>
                                                    </li>
                                                   
                                                </ul>
                                            </div>
                                            <div className="sx-post-title ">
                                                <h4 className="post-title">
                                                    <NavLink to="/blogs/detail" state={{ blog }}>
                                                        {blog.title}
                                                    </NavLink>
                                                </h4>
                                            </div>
                                            <div className="sx-post-readmore">
                                                <NavLink to="/blogs/detail" state={{ blog }} title="READ MORE" rel="bookmark" className="site-button icon">
                                                    <i className="fa fa-long-arrow-right" /> Read More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionLatestBlogs;