import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Switch,
    TextField,
    Button,
    Paper,
    Divider,
    Grid,
    Container,
    Alert,
    LinearProgress,Select,
    MenuItem,
    Chip
} from '@mui/material';
import {
    Save as SaveIcon,
    Sync as SyncIcon,
    Settings as SettingsIcon,
    RestartAlt as ResetIcon,
    Notifications as NotificationsIcon,
    Storage as CacheIcon,
    CloudSync as SyncIcon2
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../../../../../api/axios';

// Constants
const DEFAULT_SETTINGS = {
    enableCaching: true,
    cacheDuration: 30,
    enableOfflineSync: true,
    syncInterval: 15,
    maxRetryAttempts: 3,
    syncOnStartup: true,
    enableNotifications: true,
    lowStockThreshold: 10
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff9800',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ff5722',
        },
        background: {
            default: '#fff8f0',
            paper: '#ffffff',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 600,
        },
        h6: {
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '8px 16px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 8,
                    },
                },
            },
        },
    },
});



const GeneralSettingsPage = () => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const [restaurants, setRestaurants] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [settings, setSettings] = useState(DEFAULT_SETTINGS);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [lastSync, setLastSync] = useState(null);


    
    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const response = await axios.get(
                    `businessEntities/getAllRestaurantsProductsByGroupId/${userData.Group_ID}`,
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: false,
                    }
                );
                setRestaurants(response.data.restaurants);
                
                if (response.data.restaurants.length > 0) {
                    setSelectedRestaurant(response.data.restaurants[0].business_entity_id);
                }
            } catch (error) {
                console.error('Error fetching restaurants:', error);
                setError("Failed to load restaurants");
            }
        };
        fetchRestaurants();
    }, []);

    
    useEffect(() => {
        if (!selectedRestaurant) return;

        const fetchSettings = async () => {
            setIsLoading(true);
            setError(null);
            
            try {
                const settingsResponse = await axios.get(
                    `restaurantSettings/getAll/${selectedRestaurant}`,
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: false,
                    }
                );

          
                if (settingsResponse.data) {
                    setSettings({
                        enableCaching: settingsResponse.data.enable_caching ?? DEFAULT_SETTINGS.enableCaching,
                        cacheDuration: settingsResponse.data.cache_duration_minutes ?? DEFAULT_SETTINGS.cacheDuration,
                    });
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load settings. Using default values.");
                setSettings(DEFAULT_SETTINGS);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSettings();
    }, [selectedRestaurant]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSettings(prev => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        setSettings(prev => ({
            ...prev,
            [name]: value === '' ? '' : Number(value)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError(null);

        try {
            await axios.put(
                `restaurantSettings/update/${selectedRestaurant}`,
                {
                    enable_caching: settings.enableCaching,
                    cache_duration_minutes: settings.cacheDuration,
                    enable_offline_sync: settings.enableOfflineSync,
                },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: false,
                }
            );

            setSaveSuccess(true);
            setTimeout(() => setSaveSuccess(false), 3000);
        } catch (err) {
            console.error("Error saving settings:", err);
            setError("Failed to save settings. Please try again.");
        } finally {
            setIsSaving(false);
        }
    };

    const handleResetToDefaults = () => {
        setSettings(DEFAULT_SETTINGS);
    };

    const handleManualSync = async () => {
        try {
            const response = await axios.post(
                `restaurantSettings/sync/${selectedRestaurant}`,
                {},
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: false,
                }
            );

            if (response.status === 200) {
                setLastSync(new Date());
                setSaveSuccess(true);
                setTimeout(() => setSaveSuccess(false), 3000);
                
            }
        } catch (err) {
            console.error("Error syncing data:", err);
            setError("Failed to sync data. Please try again.");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ py: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <SettingsIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
                    <Typography variant="h6" color="primary">
                        General Configuration Settings
                    </Typography>
                </Box>

                {/* Restaurant Selection Dropdown */}
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Select Restaurant
                    </Typography>
                    <Select
                        fullWidth
                        value={selectedRestaurant || ''}
                        onChange={(e) => setSelectedRestaurant(e.target.value)}
                        sx={{ mb: 2 }}
                        disabled={isLoading}
                    >
                        {restaurants.map(restaurant => (
                            <MenuItem 
                                key={restaurant.business_entity_id} 
                                value={restaurant.business_entity_id}
                            >
                                {restaurant.business_name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                {isLoading && <LinearProgress color="primary" sx={{ mb: 2 }} />}
                {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
                {saveSuccess && (
                    <Alert severity="success" sx={{ mb: 3 }}>
                        Updated successfully!
                    </Alert>
                )}

                {selectedRestaurant && (
                    <>
                        <Grid container spacing={3}>
                            {/* Caching Settings */}
                            <Grid item xs={12} md={6}>
                                <Paper elevation={2} sx={{ p: 3, height: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                        <CacheIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="h6">
                                            Data Caching (Menu Items & Category)
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ mb: 3 }} />

                                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                        <Typography variant="body1" sx={{ mr: 2 }}>
                                            Enable Data Caching
                                        </Typography>
                                        <Switch
                                            name="enableCaching"
                                            checked={settings.enableCaching}
                                            onChange={handleChange}
                                            color="primary"
                                            disabled={isLoading}
                                        />
                                    </Box>

                                    {settings.enableCaching && (
                                        <>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography variant="body1" sx={{ mb: 1 }}>
                                                    Cache Duration (minutes)
                                                </Typography>
                                                <Typography variant="caption" sx={{ mb: 1 }}>
                                                    This is the duration for which the data will be stored locally before next fetch from server.
                                                </Typography>
                                                <TextField
                                                    name="cacheDuration"
                                                    type="number"
                                                    value={settings.cacheDuration}
                                                    onChange={handleNumberChange}
                                                    fullWidth
                                                    inputProps={{ min: 1, max: 1440 }}
                                                    variant="outlined"
                                                    size="small"
                                                    disabled={isLoading}
                                                />
                                            </Box>

                                        </>
                                    )}
                                </Paper>
                            </Grid>

                            {/* Sync Settings */}
                            {/* <Grid item xs={12} md={6}>
                                <Paper elevation={2} sx={{ p: 3, height: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                        <SyncIcon2 color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="h6">
                                            Data Synchronization
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ mb: 3 }} />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SyncIcon />}
                                        onClick={handleManualSync}
                                        fullWidth
                                        sx={{ mb: 2 }}
                                        disabled={isLoading || isSaving}
                                    >
                                        Sync Data Now
                                    </Button>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Last sync status:
                                        </Typography>
                                        <Chip 
                                            label={lastSync ? lastSync.toLocaleString() : 'Never synced'} 
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Paper>
                            </Grid> */}
                        </Grid>

                        {/* Action Buttons */}
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 4,
                            gap: 2
                        }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleResetToDefaults}
                                startIcon={<ResetIcon />}
                                sx={{ flex: 1 }}
                                disabled={isLoading || isSaving}
                            >
                                Reset to Defaults
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                disabled={isLoading || isSaving}
                                onClick={handleSubmit}
                                sx={{ flex: 1 }}
                            >
                                {isSaving ? "Saving..." : "Save Settings"}
                            </Button>
                        </Box>
                    </>
                )}
            </Container>
        </ThemeProvider>
    );
};


export default GeneralSettingsPage;


   // const handleManualSync = () => {
    //     // Simulate sync
    //     const newSyncTime = new Date();
    //     setLastSync(newSyncTime);
    //     localStorage.setItem('lastSyncTime', newSyncTime.toString());
    //     alert('Data synchronized successfully!');
    // };

   {/* Sync Settings */}
                    {/* <Grid item xs={12} md={6}>
                        <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <SyncIcon2 color="primary" sx={{ mr: 1 }} />
                                <Typography variant="h6">
                                    Offline Sync
                                </Typography>
                            </Box>
                            <Divider sx={{ mb: 3 }} />

                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Typography variant="body1" sx={{ mr: 2 }}>
                                    Enable Offline Sync
                                </Typography>
                                <Switch
                                    name="enableOfflineSync"
                                    checked={settings.enableOfflineSync}
                                    onChange={handleChange}
                                    color="primary"
                                />
                            </Box>

                            {settings.enableOfflineSync && (
                                <>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Sync Interval (minutes)
                                        </Typography>
                                        <TextField
                                            name="syncInterval"
                                            type="number"
                                            value={settings.syncInterval}
                                            onChange={handleNumberChange}
                                            fullWidth
                                            inputProps={{ min: 1, max: 1440 }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Box>

                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Max Retry Attempts
                                        </Typography>
                                        <TextField
                                            name="maxRetryAttempts"
                                            type="number"
                                            value={settings.maxRetryAttempts}
                                            onChange={handleNumberChange}
                                            fullWidth
                                            inputProps={{ min: 1, max: 10 }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="body1" sx={{ mr: 2 }}>
                                            Sync on App Startup
                                        </Typography>
                                        <Switch
                                            name="syncOnStartup"
                                            checked={settings.syncOnStartup}
                                            onChange={handleChange}
                                            color="primary"
                                        />
                                    </Box>
                                </>
                            )}
                        </Paper>
                    </Grid> */}

                    {/* Notification Settings */}
                    {/* <Grid item xs={12} md={6}>
                        <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <NotificationsIcon color="primary" sx={{ mr: 1 }} />
                                <Typography variant="h6">
                                    Notifications
                                </Typography>
                            </Box>
                            <Divider sx={{ mb: 3 }} />

                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Typography variant="body1" sx={{ mr: 2 }}>
                                    Enable Notifications
                                </Typography>
                                <Switch
                                    name="enableNotifications"
                                    checked={settings.enableNotifications}
                                    onChange={handleChange}
                                    color="primary"
                                />
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body1" sx={{ mb: 1 }}>
                                    Low Stock Threshold
                                </Typography>
                                <TextField
                                    name="lowStockThreshold"
                                    type="number"
                                    value={settings.lowStockThreshold}
                                    onChange={handleNumberChange}
                                    fullWidth
                                    inputProps={{ min: 1 }}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Paper>
                    </Grid> */}

                    {/* Sync Now Card */}
                    {/* <Grid item xs={12} md={6}>
                        <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
                            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                                <SyncIcon color="primary" sx={{ mr: 1 }} />
                                Data Synchronization
                            </Typography>
                            <Divider sx={{ mb: 3 }} />

                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SyncIcon />}
                                onClick={handleManualSync}
                                fullWidth
                                sx={{ mb: 2 }}
                            >
                                Force Sync Data Now
                            </Button>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2
                            }}>
                                <Typography variant="body2" color="text.secondary">
                                    Last sync status:
                                </Typography>
                                <Chip
                                    label={lastSync.toLocaleString()}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                />
                            </Box>
                        </Paper>
                    </Grid> */}