import { NavLink, useLocation  } from "react-router-dom";
import BBQImage from "../../elements/bbq-image";
import { useState } from "react";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { useNavigate } from 'react-router-dom'

function HeaderMain() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const isStandardUser = isAuthenticated && userData.super_role_id === 0;

    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };

    const [isActive, setIsActive] = useState(false);

    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(() => {
        loadScript("js/mobilenav.js")
    })

    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        loadScript("js/mobilenav.js");

        // Scroll event listener
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const linkStyle = {
        color: isScrolled ? "#FFFFFF" : isHomePage ? "#5B1873" : "#FFFFFF",
        textDecoration: 'none'
    };

    return (
        <>
            <header className={"header-style2 site-header mobile-sider-drawer-menu full-navbar " + (isActive ? "active" : "")}>
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="d-flex items-center logo-header-inner logo-header-one">
                                    <NavLink to="/">
                                        <BBQImage src="images/BBQ_LOGO.png" alt="#" />
                                    </NavLink>
                                    <h4 style={{...linkStyle, marginLeft: "17px" }}>BuyByeQ</h4>
                                    <div style={{width: "2.3px", height: "24px", backgroundColor: "orange", borderRadius: "8px", margin: "2px 4px 8px 5px"}}></div>
                                    <h5 style={{ ...linkStyle, fontFamily: "cursive" }}>Serve</h5>
                                </div>
                            </div>
                           
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}>
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>

                            <div className="extra-nav d-flex align-content-center d-none d-lg-block">
                                <ul className="sidenav-main gap-4">
                                    <li><NavLink to="/contact-us" className="sidenav" style={linkStyle}>Contact Us</NavLink></li>
                                    {!isAuthenticated ? (
                                        <li><NavLink to="/login" className="sidenav" style={linkStyle}>Login</NavLink></li>
                                    ) : (
                                        <li>
                                            <NavLink to={isStandardUser ? "/config" : "/agents/dashboard"} className="sidenav" style={linkStyle}>Dashboard</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                           
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse">
                                <ul className="nav navbar-nav">
                                    <li className="active">
                                        <NavLink to="/" style={linkStyle}>Home</NavLink>
                                    </li>
                                    <li><NavLink to="/faq" style={linkStyle}>FAQ</NavLink></li>
                                    <li><NavLink to="/all-products" style={linkStyle}>Products</NavLink></li>
                                    <li><NavLink to="/blogs"  style={linkStyle}>Blogs</NavLink></li>
                                    <li><NavLink to="/contact-us" className="d-lg-none d-md-none" style={linkStyle}>Contact Us</NavLink></li>
                                    {!isAuthenticated ? (
                                        <li><NavLink to="/login" className="d-lg-none d-md-none" style={linkStyle}>Login</NavLink></li>
                                    ) : (
                                        <li>
                                            <NavLink to="/config" className="d-lg-none d-md-none" style={linkStyle}>Dashboard</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderMain;