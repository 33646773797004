import axios from "../../../../../../api/axios";

function SectionBlogSingle({ blog, previousBlog, nextBlog, navigate }) {
    if (!blog) {
        return <div>Loading...</div>;
    }

    const imageUrl = `${axios.defaults.baseURL}${blog.image}`.replace(/([^:]\/)\/+/g, "$1");

    return (
        <>
            <div className="blog-single-space">
                {/* BLOG START */}
                <div className="sx-post-media sx-img-effect img-reflection m-b30">
                    <img src={imageUrl} alt="Blog Image" />
                </div>
                <div className="single-blog-post blog-detail m-b30">
                    <div className="sx-post-meta ">
                        <ul>
                            <li className="post-date">
                                <i className="fa fa-calendar" />
                                {new Date(blog.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                            </li>
                    
                        </ul>
                    </div>
                    <div className="sx-post-title ">
                        <h2 className="post-title">{blog.title}</h2>
                    </div>
                    <div className="sx-post-text">
                        <p>{blog.content}</p>
                    </div>

                    {/* Next and Previous Buttons (only show if there are multiple blogs) */}
                    {(previousBlog || nextBlog) && (
                        <div className="s-post-pagination-control previous-next previous next">
                            {previousBlog && (
                                <div className="paging-left paging-item">
                                    <div className="paging-content">
                                        <button
                                            className="paging-link"
                                            onClick={() => navigate("/blogs/detail", { state: { blog: previousBlog } })}
                                        >
                                            <i className="fa fa-long-arrow-left" />
                                        </button>
                                        <div className="paging-content-inner">
                                            <button
                                                className="paging-link2"
                                                onClick={() => navigate("/blogs/detail", { state: { blog: previousBlog } })}
                                            >
                                                Prev Article
                                            </button>
                                            <h4 className="paging-title">
                                                {previousBlog.title}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {nextBlog && (
                                <div className="paging-right paging-item">
                                    <div className="paging-content reverse">
                                        <button
                                            className="paging-link"
                                            onClick={() => navigate("/blogs/detail", { state: { blog: nextBlog } })}
                                        >
                                            <i className="fa fa-long-arrow-right" />
                                        </button>
                                        <div className="paging-content-inner">
                                            <button
                                                className="paging-link2"
                                                onClick={() => navigate("/blogs/detail", { state: { blog: nextBlog } })}
                                            >
                                                Next Article
                                            </button>
                                            <h4 className="paging-title">
                                                {nextBlog.title}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SectionBlogSingle;