
// import Chart from "../../components/chart/Chart";

import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import GeneralSettingsPage from "./general-settings";

const SettingsHomePage = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const restaurantId= userData?.businessEntityId;
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <GeneralSettingsPage restaurantId={restaurantId} />
        
      </div>
    </div>
  );
};

export default SettingsHomePage;