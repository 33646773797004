import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TestimonialsPage() {
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(false);


    const fetchContent = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`content/all`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
            });

            const isValidRawDraftContentState = (content) => {
                return (
                    content &&
                    typeof content === "object" &&
                    Array.isArray(content.blocks) &&
                    typeof content.entityMap === "object"
                );
            };

            const parsedTestimonials =
                response.data.testimonials?.map((testimonial) => {
                    const parsedDescription = testimonial.description
                        ? JSON.parse(testimonial.description)
                        : null;
                    const editorState =
                        parsedDescription &&
                        isValidRawDraftContentState(parsedDescription)
                            ? EditorState.createWithContent(
                                  convertFromRaw(parsedDescription)
                              )
                            : EditorState.createEmpty();

                    return {
                        ...testimonial,
                        description: editorState.getCurrentContent().getPlainText(),
                    };
                }) || [];

            setTestimonials(parsedTestimonials);
        } catch (error) {
            console.log("Error fetching content: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    // Carousel settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };

    return (
        testimonials.length > 0 ?
        <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
            <div className="container">
                <div className="section-content">
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Testimonials</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">What Clients Say?</h2>
                        </div>
                    </div>

                    {loading ? (
                        <div className="text-center">...</div>
                    ) : testimonials.length > 0 ? (
                        <Slider {...settings}>
                            {testimonials.map((testimonial, index) => (
                                <div className="masonry-item m-b30" key={index}>
                                    <div
                                        className="testimonial-3 hover-animation-1 sx-bg-light col-lg-8 mx-auto"
                                        style={{ borderRight: "7px solid orange" }}
                                    >
                                        <div className="imgtestimonial">
                                            <img
                                                src={`${axios.defaults.baseURL}${testimonial.image}`.replace(/([^:]\/)\/+/g, "$1")}
                                                alt="testimonial"
                                                style={{ width: '900px', height: '100%', objectFit: 'cover' }}
                                            />
                                        </div>
                                        <div>
                                            <div className="testimonial-detail text-left px-5 py-2">
                                                <div className="testimonial-user-info">
                                                    <span className="testimonial-position">
                                                        Restaurant Name: {testimonial.restaurant_name}
                                                    </span>
                                                    <h4 className="testimonial-name">
                                                        Owner’s Name: {testimonial.customer_name}
                                                    </h4>
                                                    <h4 className="testimonial-name">
                                                        Product: {testimonial.product_name}
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="testimonial-text text-left px-5">
                                                <p>{testimonial.description}</p>
                                            </div>
                                            <div className="testimonial-footer px-5">
                                                <div className="testimonial-quote sx-text-primary">
                                                    <i className="flaticon-quote" />
                                                </div>
                                                <ul className="sx-rating-st1">
                                                    {[...Array(testimonial.rating)].map((_, i) => (
                                                        <li key={i} style={{ marginRight: "4px" }}>
                                                            <span>
                                                                <i
                                                                    className="fa fa-star"
                                                                    style={{
                                                                        color: "#FFD700",
                                                                        fontSize: "1.2em",
                                                                    }}
                                                                />
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <span className="sx-review-count">
                                                    ({testimonial.rating})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className="text-center">No testimonials available.</div>
                    )}
                </div>
            </div>
        </div>:<></>
    );
}

export default TestimonialsPage;