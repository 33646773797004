import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { EditorState, convertFromRaw } from "draft-js";
import Banner from "../../sections/common/banner";
import { bannerData } from "../../../globals/banner";

function BlogGridPage() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 9;

    const navigate = useNavigate();

    // Fetch blogs from the API
    const fetchBlogs = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`content/all`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
            });

            const isValidRawDraftContentState = (content) => {
                return (
                    content &&
                    typeof content === "object" &&
                    Array.isArray(content.blocks) &&
                    typeof content.entityMap === "object"
                );
            };

            const parsedBlogs =
                response.data.blogs?.map((blog) => {
                    const parsedContent = blog.content
                        ? JSON.parse(blog.content)
                        : null;
                    const editorState =
                        parsedContent &&
                            isValidRawDraftContentState(parsedContent)
                            ? EditorState.createWithContent(
                                convertFromRaw(parsedContent)
                            )
                            : EditorState.createEmpty();

                    return {
                        ...blog,
                        content: editorState.getCurrentContent().getPlainText(),
                    };
                }) || [];

            setBlogs(parsedBlogs);

        } catch (error) {
            console.log("Error fetching blogs: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    // Calculate total number of pages
    const totalPages = Math.ceil(blogs.length / blogsPerPage);

    // Get current blogs to display
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Banner _data={bannerData.blogs} />

            {/* SECTION CONTENT START */}
            <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
                {/* GALLERY CONTENT START */}
                <div className="container">
                    {loading ? (
                        <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100vh", 
                            padding: "40px 0", 
                            backgroundColor: "#f9f9f9", 
                        }}
                    >
                        {/* Skeleton Loading Animation */}
                        <div
                            style={{
                                width: "120px",
                                height: "120px",
                                borderRadius: "50%",
                                backgroundColor: "#e0e0e0",
                                animation: "pulse 1.5s infinite ease-in-out",
                                marginBottom: "20px",
                            }}
                        ></div>
            
                        {/* Loading Text */}
                        <p
                            style={{
                                fontSize: "1.2rem",
                                color: "#333",
                                fontWeight: "500",
                                marginTop: "20px",
                            }}
                        >
                            Loading blogs...
                        </p>
            
                        {/* CSS Animation Keyframes */}
                        <style>
                            {`
                                @keyframes pulse {
                                    0% {
                                        transform: scale(0.9);
                                        opacity: 0.7;
                                    }
                                    50% {
                                        transform: scale(1);
                                        opacity: 1;
                                    }
                                    100% {
                                        transform: scale(0.9);
                                        opacity: 0.7;
                                    }
                                }
                            `}
                        </style>
                    </div>
                    ) : (
                        <div className="masonry-outer mfp-gallery news-grid clearfix row">
                            {currentBlogs.map((blog) => {
                                const imageUrl = `${axios.defaults.baseURL}${blog.image}`.replace(/([^:]\/)\/+/g, "$1");
                                return (
                                    <div className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30" key={blog.id}>
                                        <div className="sx-latest-post-st-1">
                                            <div className="sx-post-media sx-img-effect img-reflection">
                                                <NavLink to="/blogs/detail">
                                                    <img
                                                        src={imageUrl}
                                                        alt="Blog Image"
                                                        style={{ width: "400px", height: "300px", objectFit: "cover" }}
                                                    />
                                                </NavLink>
                                            </div>
                                            <div className="sx-post-info p-t30">
                                                <div className="sx-post-meta">
                                                    <ul>
                                                        <li className="post-author">
                                                            <NavLink to="/blogs/detail">
                                                                <i className="fa fa-calendar" /> Date Posted
                                                            </NavLink>
                                                        </li>
                                                        <li className="post-date">
                                                            <NavLink to="/blogs/detail">
                                                                {new Date(blog.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                                            </NavLink>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="sx-post-title">
                                                    <h4 className="post-title"><NavLink to="/blogs/detail" state={{ blog }}>{blog.title}</NavLink>
                                                    </h4>
                                                </div>
                                                <div className="sx-post-readmore">
                                                    <NavLink to="/blogs/detail" state={{ blog }} title="READ MORE" rel="bookmark" className="site-button icon">
                                                        <i className="fa fa-long-arrow-right" /> Read More
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {/* Pagination */}
                    <div className="sx-pagination-wra text-center">
                        <ul className="sx-pagination">
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <span className="fa fa-long-arrow-left" />
                                </button>
                            </li>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <li key={index + 1} className={currentPage === index + 1 ? "active" : ""}>
                                    <button onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    <span className="fa fa-long-arrow-right" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* GALLERY CONTENT END */}
            </div>
            {/* SECTION CONTENT END  */}
        </>
    );
}

export default BlogGridPage;